<template>
  <div>
    <div id="wmap" style="width: 100%; height: 60vh"></div>
  </div>
</template>

<script>
import _ from 'lodash'
import * as echarts from 'echarts'
import theme from '@/libs/colorTheme'
import world from '@/geo/worldExAntarctica50m.json'
echarts.registerTheme('kpmg', theme)
echarts.registerMap('world', world)

function delay (period) {
  return new Promise(resolve => {
    setTimeout(resolve, period)
  })
}

export default {
  components: {
  },
  props: ['config'],
  computed: {
  },
  created: async function () {
    await delay(10)
    this.load()
  },
  data () {
    return {
    }
  },
  methods: {
    load: async function () {
      const data = this.config.data
      let min, max
      let showNumeric = false
      if (!this.config.chart?.skipNumeric) {
        _.each(data, row => {
          if (row.value) {
            if (!_.isNaN(_.toNumber(row.value))) {
              row.value = _.toNumber(row.value)
              showNumeric = true
            }
          }
        })
        if (showNumeric) {
          min = _.minBy(data, 'value').value
          max = _.maxBy(data, 'value').value
          if (min === max) {
            min = max - 1
          }
        }
      }
      const option = {
        tooltip: {
          trigger: 'item',
          showDelay: 0,
          transitionDuration: 0.2
        },
        toolbox: {
          show: true,
          left: 'right',
          top: 'top',
          feature: {
            dataView: { readOnly: false },
            restore: {},
            saveAsImage: {}
          }
        },
        series: [
          {
            name: this.config.header,
            zoom: 1.2,
            type: 'map',
            roam: true,
            map: 'world',
            emphasis: {
              label: {
                show: true
              }
            },
            data: data,
            scaleLimit: {
              max: 20,
              min: 0.9
            }
          }
        ]
      }
      if (showNumeric) {
        option.visualMap = [
          {
            show: true,
            type: 'continuous',
            left: 'left',
            min: min,
            max: max,
            inRange: {
              color: [
                '#aceaff', // ligh blue
                '#1e49e2', // cobalt blue
                '#00338d' // dark blue
              ]
            },
            text: ['High', 'Low'],
            calculable: true
          }
        ]
      }
      if (this.config.chart?.dataGetter === 'directObjectOptions') {
        option.tooltip.formatter = (params) => {
          let text = 'No data'
          if (params.value >= 0) {
            text = this.config.chart.fullField.options[params.value]
          } else {
            text = 'no measure'
          }
          return params.name + ': ' + text
        }
        const pieces = []
        _.each(this.config.chart.fullField.options, (value, index) => {
          pieces.push({ value: index, label: value })
        })
        option.visualMap = {
          show: true,
          type: 'piecewise',
          left: 'left',
          pieces: pieces,
          inRange: {
            color: [
              '#aceaff', // ligh blue
              '#00338d' // dark blue
            ]
          }
        }
      }
      if (this.config.chart?.dataGetter === 'directObjectBoolean') {
        option.tooltip.formatter = function (params) {
          let text = 'No data'
          if (params.value === 1) {
            text = 'Yes'
          } else if (params.value === 0) {
            text = 'No'
          } else {
            text = 'no measure'
          }
          return params.name + ': ' + text
        }
        option.visualMap = {
          show: true,
          type: 'piecewise',
          left: 'left',
          pieces: [
            { value: 0, label: 'No', color: '#aceaff' },
            { value: 1, label: 'Yes', color: '#00338d' }
          ],
          inRange: {
            color: [
              '#aceaff', // ligh blue
              '#00338d' // dark blue
            ]
          }
        }
      }
      await delay(100)
      const chart = echarts.init(document.getElementById('wmap'), 'kpmg')
      chart.setOption(option)
      chart.on('click', (event) => {
        this.$emit('map-click', event.name)
      })
    },
    reload: async function () {
      echarts.dispose(document.getElementById('wmap'))
      await delay(10)
      this.load()
    }
  },
  watch: {
    'config': 'reload'
  }
}
</script>

<style>
</style>
