<template>
<div>
  <b-container fluid class="mt-4">
    <b-row class="my-5 text-center" v-if="busy">
      <b-col>
        <b-spinner label="Loading..."></b-spinner>
      </b-col>
    </b-row>
    <b-row class="mb-4" v-if="!busy">
      <b-col>
        <h1 style="font-size: 24px; font-weight: bold;">World ESG Tax Dashboard</h1>
        <p>Explore ESG taxes and incentives around the world.
        </p>
      </b-col>
    </b-row>
    <b-row class="mb-4" v-if="!busy">
      <b-col style="background: #FFFFFF 0% 0% no-repeat padding-box; box-shadow: 0px 12px 25px #00000029; border-radius: 2px; opacity: 1">
        <world-map-simple v-if="Array.isArray(mapData.data) && mapData.data.length > 0" :config="mapData" v-on:map-click="mapClick($event)" />
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import _ from 'lodash'
import * as echarts from 'echarts'
import theme from '@/libs/colorTheme'
import WorldMapSimple from '@/components/charts/v1/worldMapSimple.vue'

echarts.registerTheme('kpmg', theme)

function getRandomKey () {
  return Math.floor(Math.random() * 10000)
}

function delay (period) {
  return new Promise(resolve => {
    setTimeout(resolve, period)
  })
}

export default {
  name: 'TaxMap',
  components: {
    WorldMapSimple
  },
  computed: {
    mapData: function () {
      let results = []
      // create an array of unique countries from this.rows.country
      let data = []
      this.rows.forEach(x => {
        data.push({ name: x.country })
      })
      data = _.uniqBy(data, 'name')
      // add property value to each object in data which counts how many countries there are in this.rows with the same country name
      data.forEach(x => {
        x.value = this.rows.filter(y => y.country === x.name).length
      })
      results = {
        data: _.orderBy(data, ['value'], ['desc']),
        name: 'Number of items',
        header: 'Taxes and incentives',
        headerRecord: 'tax / incentive',
        key: getRandomKey()
      }
      return results
    },
    rows: function () {
      return this.$store.state.initiativesTax
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  data () {
    return {
      busy: false
    }
  },
  created: async function () {
    this.busy = true
    document.title = "Tax World Dashboard"
    await delay(300)
    this.busy = false
  },
  methods: {
    mapClick: function (country) {
      this.$router.push({ name: 'TaxCountry', params: { country: country } })
    }
  }
}
</script>

<style>
</style>
